:root {
}

* {
    box-sizing: border-box;
}

html {
    line-height: 1.6;
}

body {
    margin: 0;
    background: url("../../imgs/background.png") center fixed;
    background-size: 400px;
}

img {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    line-height: 1.2;
    font-weight: 400;
}

p {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
}
