// flex helpers
.flex {
    display: flex;
    &.column {
        flex-direction: column;
    }
    &.justify-center {
        justify-content: center;
    }
    &.justify-space-between {
        justify-content: space-between;
    }
    &.justify-around {
        justify-content: space-around;
    }
    &.align-center {
        align-items: center;
    }
    &.wrap {
        flex-wrap: wrap;
    }
    &.column-reverse {
        flex-direction: column-reverse;
    }
    &.justify-flex-end {
        justify-content: flex-end;
    }
    &.align-flex-start {
        align-items: flex-start;
    }
}

.clean-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.clean-link {
    text-decoration: none;
    color: inherit;
}
