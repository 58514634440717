.drawing {
    background-color: #17a2b8;
    min-height: 100vh;
    gap: 25px;

    .leaderboard-screen-overlay {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 5;
    }

    .leaderboard {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: fit-content;
        width: 320px;
        background-color: white;
        color: black;
        padding: 15px;
        text-align: center;
        border-radius: 4px;

        .title {
            border-bottom: 1px solid lightgray;
            margin-bottom: 15px;

            h1 {
                margin-bottom: 5px;
                font-family: "montserrat";
                font-size: rem(26px);
            }
        }

        .leaderboard-list {
            gap: 5px;
        }

        .leaderboard-preview {
            padding: 5px;
            border: 1px solid lightgray;
            border-radius: 4px;
        }

        .footer {
            margin-top: 10px;
            padding: 5px;
            border-top: 1px solid lightgray;
        }
    }

    .game-header {
        width: 100%;
        padding: 15px;
        background-color: white;
        font-size: rem(16px);

        @media (min-width: 600px) {
            font-size: rem(20px);
        }

        @media (min-width: 800px) {
            font-size: rem(24px);
        }
    }

    .drawing-body {
        gap: 30px;
        flex-wrap: wrap;

        .player-list {
            padding: 0 15px 15px 15px;
            display: none;

            @media (min-width: 1357px) {
                display: block;
            }

            .player-list-preview {
                width: 300px;
                padding: 10px 15px;
                border-radius: 3px;
                background-color: white;
                border-bottom: 1px solid lightgray;
            }
        }

        .canvas-wrapper {
            gap: 10px;
            width: 320px;
            height: 320px;
            margin-bottom: 30px;

            @media (min-width: 550px) {
                width: 500px;
                height: 350px;
            }
            @media (min-width: 850px) {
                width: 600px;
                height: 450px;
                margin-bottom: 0;
            }
            button {
                width: 70px;
            }
        }

        .chat {
            background-color: white;
            //MOBILE FIRST
            height: 190px;
            width: 320px;
            border-radius: 3px;
            overflow-y: auto;

            @media (min-width: 550px) {
                height: 325px;
            }

            @media (min-width: 950px) {
                height: 450px;
            }

            .chat-item {
                padding: 10px;
                border-bottom: 1px solid lightgray;
            }

            form {
                padding: 10px;
                gap: 5px;

                input {
                    padding: 8px;
                    outline: none;
                    font-size: 16px;
                    flex-grow: 1;
                }

                button {
                    background-color: #0079bf;
                    border: none;
                    color: #fff;
                    padding: 6px 6px;
                    border-radius: 3px;
                    height: 35px;
                    font-size: rem(14px);

                    &:hover {
                        background-color: #026aa7;
                    }
                }
            }
        }
    }
}
